import React from "react";
import Layout from "./../components/Layout";
import "./about.scss";

const AboutPage = () => {
  return (
    <Layout>
      <div className="container">
        <p>about-page</p>
      </div>
    </Layout>
  );
};

export default AboutPage;
